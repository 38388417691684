h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0 !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
}
.table-report th,
.table-report td {
    padding: 0.25rem !important;
    border: 1px solid #080808 !important;
}


$image-bg: gray;
$image-border: white;
$image-border-current: aqua;

.image {
    opacity: 0.8;
    width: 200px;
    height: 160px;
    background-position: center center;
    background-color: $image-bg;
    display: inline-block;
    margin: 10px;

    &:hover {
      opacity: 1;
    }
}

.radio-img {
    > input { 
        display:none;
    }
    > .image{
  cursor:pointer;
      border: 2px solid black;

}
    > input:checked + .image{ 
  border:2px solid orange;
}
    
}

