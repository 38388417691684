.noti-icon .badge{
    left :23px;
}
.mt-2
{
 margin-top: 20px!important;
}

.dataTables_filter, .dataTables_paginate
{
    float: right;
}

.rdw-editor-main
{
    border: 1px solid #eeeef5;
    height: 239px;
}
.dz-message
{
     text-align: center;
    padding: 100px;
}
.fcYNFc
{
        background-color: #ffffff!important;
}
.task-box 
{
    border : 1px solid #e1e1e6;
}
.react-datepicker-wrapper
{
    width: 100%!important;
}

.ReactModal__Overlay
{
  z-index: 1001!important;
}

.fc-event .fc-content{
    padding: 5px;
    color: #FFF;
}
.chat-conversation .right .conversation-list{
margin-right: 15px;
}

.external-event{
    &:hover{
        cursor: pointer;
    }
}

.border-0
{
    border:0px!important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: rgb(223, 216, 224) !important;
}